// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


// Forms
.form-control {
  border: 0 none;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #f1f1f1;
  transition: all .3s ease-in-out;

  &:focus {
    box-shadow: none;
    border-color: $brand-primary;
  }
}

textarea {
  resize: vertical;
}

// Contact Form 7
span.wpcf7-not-valid-tip {
  text-align: left;
  font-size: 13px;
  color: $brand-primary;
  padding: 5px 0;
  font-style: italic;
}

div.wpcf7-validation-errors {
  border: 0 none;
  padding: 20px;
  color: $brand-white;
  background: linear-gradient(45deg, $brand-primary 0%, darken($brand-primary,10%) 100%);
}
div.wpcf7-mail-sent-ok {
  border: 0 none;
  padding: 20px;
  color: $brand-white;
  background: linear-gradient(45deg, $brand-secondary 0%, darken($brand-secondary,10%) 100%);
}


// Selectize
.selectize-input.full {
  background-color: transparent;
  color: #555;
  border: 0;
  text-transform: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #f1f1f1;
  font-size: 14px;
  text-align: left;
}

.selectize-control.single .selectize-input:after {
  border-color: #f1f1f1 transparent transparent;
}

.selectize-control.single .selectize-input.input-active {
  background: transparent;

  &:after {
    border-color: #f1f1f1 transparent transparent;
  }
}

.selectize-dropdown {
  text-align: left;
  background: #fff;
  border-radius: 0;
  border: 2px solid #f1f1f1;
  box-shadow: none;
  color: #555;
  margin-top: -3px;
}
