// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
$text-color:            #010101;
$brand-primary:         #b11d09;
$brand-secondary:       #222f3a;
$brand-white:           #fff;
$brand-black:           #000;


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Fonts
$font-family-sans-serif:      "Muli", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family:        $font-family-sans-serif;
$headings-font-weight:        900;
$font-size-base:              16px;

// Border
$border-radius-base: 0;
