.content-info {
  position: relative;
  padding: 50px 0;
  clear: both;

  .copyright {
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #c5c4c4;
  }
}
