// Nav
header.nav {
	position: absolute;
	margin: 40px 0;
	padding: 0;
	width: 100%;
	z-index: 99;

	.brand {

      a {
		margin: 0;
		padding: 0;
		display: inline-block;
		text-indent: -9999px;
        width: 195px;
        height: 90px;
        background-image: url(../images/merieultdonze-logo.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 195px 90px;
		font-size: 36px;
		font-weight: 700;
		padding-left: 0;

        @media(min-width: $screen-sm) {
          width: 260px;
          height: 120px;
          background-size: 260px 120px;
        }

        .svg & {
          background-image: url(../images/merieultdonze-logo.svg);
        }

      }
    }

	.primary-nav {
        margin-top: 30px;
        text-align: right;

		@media screen and (max-width: $screen-md ) {
			display: none;
		}
	}
	ul {
		padding: 0;
		margin: 2px 0 0 0;

        @media (max-width: $screen-md) {
          display: inline-block !important;
        }

		li {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;

			a {
                padding: 5px 10px;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
                color: $text-color;
                letter-spacing: -1px;
//                text-shadow: 2px 5px 15px rgba(0,0,0,.15);
                transition: all .5s ease;
                &:hover,
                &:focus {
                  color: $brand-primary;
                  text-decoration: none;
                  background: transparent;
				}
			}
			&.menu-item-has-children {
				position: relative;
				.sub-menu {
					width: 200px;
					box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.2);
					z-index: 1002;
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 40px;
					left: 0;
					text-align: left;
					background: $brand-white;
					padding: 20px;
                    border-radius: 4px;

                    &.animated {
                      animation-duration: .5s;
                    }

					&:before {
						bottom: 100%;
						// left: 30%;
						left: 40px;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-bottom-color: #fff;
						border-width: 8px;
						margin-left: -8px;
					}

					li {
						display: block;
						margin-bottom: 7px;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							padding: 2px 0;
							display: block;
							color: lighten($brand-black, 60%);
							line-height: 1.2;
							text-transform: lowercase;
							font-size: 15px;
							&:hover {
								color: $brand-primary;
							}
						}
					}
				}
				&:hover, &:focus {
                  a {
                      color: $brand-primary;
                  }
                  .sub-menu {
                       visibility: visible;
                       opacity: 1;

                    & > ul {
                      transition: all 0 ease !important;
                    }

                  }
               }
			}

		}
	}
}

// Burger Menu
.nav-toggle {
  position: absolute;
  cursor: pointer;
  text-decoration: none;
  right: 0px;
  top: 60px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 103;
  border-bottom: none!important;

  @media screen and (max-width: $screen-md) {
  	display: block;
  }

  &.active i {
		&::before, &::after {
			background: $text-color;
		}
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  display: inline-block;
	  width: 25px;
	  height: 2px;
	  color: $text-color;
	  font: bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: $text-color;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	  content:'';
		  width: 25px;
		  height: 2px;
		  background: $text-color;
		  position: absolute;
		  left:0;
		  transition:all .2s ease-out;
	  }
  }

  i::before {
    top: -7px;
  }
  i::after {
    bottom: -7px;
  }
  &.active i {
      background: transparent;

      &::before {
        top:0;
        transform: rotateZ(45deg);
      }
      &::after {
        bottom:0;
        transform: rotateZ(-45deg);
      }
  }

}
