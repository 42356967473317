#page {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	transition: all .5s ease;

	.offcanvas & {

		&:after {
			transition: all 2s ease;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 100;
			background: rgba(0,0,0,.7);
			content: "";
		}
	}
}

#hero {
  padding-top: 200px;

  @media(min-width:$screen-md) {
    padding-top: 40px;
  }
  .lead {
    font-size: 18px;
    font-weight: 700;
  }

  h1 {
    font-size: 28px;

    @media(min-width: $screen-md) {
      font-size: 41px;
    }
  }

  .image {
    .inner {
      .image-on-top {
        @media(min-width: $screen-sm) {
          max-width: 350px;
          left: -180px;
          bottom: -180px;
        }
        @media(min-width: $screen-md) {
          max-width: inherit;
          max-height: inherit;
        }
      }
    }
  }
}

.section {
  padding: 60px 0 120px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  h2 {
    font-size: 28px;
    margin-bottom: 30px;

    @media(min-width: $screen-md) {
      font-size: 41px;
    }
  }
  .lead {
    font-size: 20px;
    font-weight: 400;
  }

  .content {
    margin-bottom: 30px;

    @media(min-width:$screen-md) {
      margin-bottom: 0;
    }

    .inner {

      @media(min-width: $screen-md) {
        padding: 0 80px 0 0;
      }

      .section-title {
        position: relative;
           h2 {
              position: relative;
              z-index: 3;

              @media(min-width:$screen-md) {
                width: 80%;
              }
          }
           .title-bg  {
              position:absolute;
              width: 100px;
              height: 100px;
              background: #ececec;
              left: -25px;
              top: -25px;
              z-index: 0;

              @media(min-width:$screen-md) {
                width: 200px;
                height: 200px;
                left: -50px;
                top: -50px;
                z-index: 0;
              }

              &:before {
                content:'';
                position: absolute;
                width: 100px;
                height: 100px;
                background: #efd2ce;
                left: -10px;
                top: -10px;
                z-index: -1;

                @media(min-width:$screen-md) {
                  width: 200px;
                  height: 200px;
                  left: -20px;
                  top: -20px;
                  z-index: -1;
                }
              }

           }
        }
      .section-content {
        position:relative;
        z-index: 5;
      }
    }
  }
  .image {
    margin-bottom: 30px;
    @media(min-width:$screen-md) {
      margin-bottom: 0;
    }
    .inner {
      position: relative;
      .image-on-top {
        position: absolute;
        max-width: 250px;
        max-height: inherit;
        bottom: -75px;
        left: -75px;
        z-index: 10;

        @media(min-width: $screen-md) {
          bottom: -150px;
          left: -150px;
          max-height: 400px;
          max-width: inherit;
        }
      }
      .on-top-carousel {
        max-width: 150px;
        max-height: 150px;
        box-shadow: 0px 15px 30px rgba(0,0,0,.3);

        @media(min-width: $screen-sm) {
          max-width: 230px;
          max-height: 230px;
        }
        @media(min-width: $screen-md) {
          max-width: 300px;
          max-height: 300px;
        }
      }
      .vertical-carousel {
        position: relative;
//        margin-bottom: 150px;

        .owl-nav {
          position: absolute;
          top: 50%;
          font-size: 70px;
          line-height: 0px;
          width: 100%;
          transform: translateY(-50%);
          color: #c5c4c4;

          @media(min-width: $screen-sm) {
            font-size: 140px;
          }

          .owl-prev,
          .owl-next {
            outline: 0;
           text-shadow: 0px 15px 20px rgba(0,0,0,.2);
          }
          .owl-prev {
            margin-left: -15px;
             @media(min-width: $screen-sm) {
              margin-left: -30px;
            }
          }
          .owl-next {
            float: right;
            margin-right: -15px;
            @media(min-width: $screen-sm) {
              margin-right: -30px;
            }
          }
        }
      }
    }
  }

  &.even {
    .content {
      .inner {
        padding: 0;

        @media(min-width: $screen-sm) {
          padding: 0 0 0 60px;
        }
        @media(min-width: $screen-md) {
          padding: 0 0 0 100px;
        }
      }
    }
    .image {
      .image-on-top {
        left: -75x;
        bottom: -75px;

        @media(min-width: $screen-sm) {
          bottom: -150px;
          right: -150px;
          left: auto;
        }
      }
    }
  }
}

#agencements-industriels {
  .section-title {
    .title-bg {
      &:before {
        background: #fee9cc !important;
        ;
      }
    }
  }
}
#systemes-de-cloisons {
  .section-title {
    .title-bg {
      &:before {
        background: #d5f1ff !important;
      }
    }
  }
}


#prestations {

	.item {
		margin-bottom: 30px;
		> a {
			display: block;
			color: $brand-black;
			position: relative;
			bottom: 0;
			overflow: hidden;
            transition: all .5s ease;
			img {
                opacity: .3;
                filter: grayscale(100);
				position: relative;
                transition: all .5s ease;
			}
			&:after {
				opacity: 0;
				visibility: visible;
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				box-shadow: inset 0px -86px 116px -8px rgba(0,0,0,0.75);
				z-index: 8;
                transition: all .5s ease;
			}
			h3 {
				z-index: 12;
				position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
				color: $text-color;
                text-align: center;
				margin: 0;
				padding: 0;
				opacity: 1;
				font-size: 18px;
				font-weight: 700;
				visibility: visible;
                transition: all .3s ease;
// 				@media screen and (max-width: $screen-sm ) {
// 					position: relative;
// 					visibility: visible;
// 					opacity: 1;
// 					bottom: 0;
// 					color: $brand-black;
//
// 					padding: 20px;
//					background: $brand-white;
// 				}

			}
			&:hover {
				box-shadow: 0px 18px 71px -10px rgba(0,0,0,0.75);

//				@media screen and (max-width: $screen-sm ) {
//					bottom: 0;
//				}

				&:after {
					opacity: 1;
					visibility: visible;
//					@media screen and (max-width: $screen-sm ) {
//						opacity: 0;
//						visibility: hidden;
//					}
				}
				h3 {
                    color: $brand-white;
					opacity: 1;
					visibility: visible;
//					bottom: 20px;
//					@media screen and (max-width: $screen-sm ) {
//						bottom: 0;
//						padding: 20px;
//						background: $brand-white;
//					}
				}
				img {
                  opacity: 1;
                  filter: grayscale(0);
					transform: scale(1.1);

//					@media screen and (max-width: $screen-sm ) {
//
//
//						transform: scale(1.0);
//					}
				}
			}
		}
}

}

#partenaires {
  padding: 80px 0;
  background: #f3f3f3;
  .item {
    text-align: center;
    img {
      display: inline-block
    }
  }
}

#contact {
  address {
    font-size: 18px;
    font-weight: 700;
  }
}

#gmap {
  height: 400px;
  .gmap {
    height: 400px;
  }
}
